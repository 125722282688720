html {
  background-image:url(https://grlz.world/images/bratsimage1.png),linear-gradient(#fff,#e4a9dc);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  background-size: flex;
}

@font-face {
  font-family: led;
  src: url("fonts/Computechnodigitronic-Regular.otf");
}
body {
  margin: 0;
  font-family: led;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
 
  background-position: bottom;
  background-repeat: no-repeat;
  
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  line-height: 10px;
}

@font-face {
  font-family: orbitron;
  src: url("fonts/Orbitron-Bold.ttf");
}

.container-mint {
  background: linear-gradient(.25turn, white, hsl(308, 82%, 85%));
  border: 2px solid black; /* Green border */
  text-align: center;
  border-radius: 30px;
  padding: 20px;
  color: pink;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: led;
  font-size: 30px;
  -webkit-text-stroke: 1px blueviolet;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

}

logo {
  padding: 20px 30px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
 margin: 5px;
   font-family: orbitron;
   font-size: 100px;
 -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
   color: magenta;
 
 
 }

.amount {color: red;}

MintContainer {  font-family: led;
}